<template>
  <div v-if="destination" class="details">
    <div v-show="details" class="container">
      <div class="row">
        <div class="data-details__item data-details__item--desc">
          <div class="data-details__item-name">Description</div>
          <div class="data-details__item-value">
            <div class="desc open">
              <div class="desc__inner" v-html="details" />
            </div>
          </div>
        </div>
      </div>
    </div>

    <RouterLink class="data-details__map" :to="destinationMapRouteTo">
      <div class="text">City map</div>
      <div class="icon">
        <TheArrowHorizIcon flip="flip" />
      </div>
      <MglMap
        :access-token="mapAccessToken"
        class="details-map-element"
        :center="[destination.longitude, destination.latitude]"
        :map-style="mapStyle"
        :zoom="12"
      />
    </RouterLink>

    <div class="data-details__relative">
      <AppSwiper
        v-if="relatedItems.length"
        :data="relatedItems"
        :disable-padding-x="false"
        title-event="destination-related"
      />
    </div>

    <div class="make-request" data-aos="fade-up">
      <div class="make-request__title">We can help</div>
      <div class="make-request__text">
        If there's something you want to see or do and you can't find it in our app, just contact us and we'll do our
        best to make it happen for you.
      </div>
      <RouterLink class="btn make-request__btn border-btn" :to="{ name: 'contact-us' }">Make a request</RouterLink>
    </div>
  </div>
</template>

<script>
import { MglMap } from 'vue-mapbox';
import { MAP_ACCESS_TOKEN, MAP_STYLE_URL } from '@/helpers/mapboxHelper';
import { routeToDestinationMap } from '@/helpers/routerHelper';

import AppSwiper from '@/components/partials/elements/AppSwiper';
import TheArrowHorizIcon from '@/components/icons/TheArrowHorizIcon.vue';

export default {
  name: 'DestinationDetailsTab',
  components: { TheArrowHorizIcon, MglMap, AppSwiper },
  props: {
    destination: {
      type: Object,
      default: null,
    },
    relatedItems: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      mapAccessToken: MAP_ACCESS_TOKEN,
      mapStyle: MAP_STYLE_URL,
    };
  },
  computed: {
    destinationMapRouteTo() {
      return routeToDestinationMap(this.destination.id, this.destination.slug);
    },
    details() {
      return this.destination
        ? this.destination.details.replace(/[\\r\\n\s]*<(p|div)>[\\r\\n\s]*<\/(p|div)>[\\r\\n\s]*/, '')
        : '';
    },
  },
};
</script>

<style lang="scss" scoped>
.details {
  padding-bottom: 50px;
}
.data-details__item {
  margin-top: 100px;
  margin-bottom: 100px;
  border-top: none;

  @media (max-width: 768px) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.data-details__map {
  display: block;
  margin-bottom: 80px;
  position: relative;

  @media (max-width: 768px) {
    margin-bottom: 25px;
  }

  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: block;
    background-color: transparent;
  }

  .icon,
  .text {
    position: absolute;
    top: 50%;
    right: 150px;
    z-index: 5;
    transform: translateY(-50%);

    @media (max-width: 768px) {
      right: 15px;
    }
  }

  .icon {
    width: 22px;

    svg {
      width: 100%;
      height: auto;
    }
  }

  .text {
    color: #363636;
    font-size: 20px;
    right: auto;
    left: 150px;
    font-weight: 700;
    letter-spacing: 5px;
    white-space: nowrap;
    text-transform: uppercase;

    @media (max-width: 768px) {
      left: 15px;
    }
  }
}

.details-map-element {
  height: 85px;

  @media (min-width: 569px) {
    height: 350px;
  }
}
</style>
